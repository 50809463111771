
    import { Component, Vue } from "vue-property-decorator";
    import { State } from "vuex-class";
    import db from "../../db";
    import store from "../../store";
    import ActionInfo from "@/components/Parts/ActionInfo.vue";
    import Dialog from "@/components/Parts/Dialog.vue";
    import ISO_Codes from "i18n-iso-countries"
  
    @Component({
        components: {ActionInfo, Dialog},
    })
  
    export default class EditTranslation extends Vue {
        @State("dynamicModules") dynamicModules;
        translations: any = {}
        fields: any = []
        tableColumns: any = [
        ]
        openNewFieldModal = false
        openNewLanguageModal = false
        showTable = true
        newFieldName = ''
        newLanguageName = ''
        languageColumns = []
        tableData = []
        deletedLanguages = []
        landCodes = []
        newField = []
  
        async mounted() {
          await this.getTranslations()
          this.landCodes = this.filterLandCodes()
        }
  
        filterLandCodes() {
          const landCodes = Object.keys(ISO_Codes.getAlpha3Codes())
          for(const column of this.tableColumns) {
            if(landCodes.includes(column['label'])) {
              landCodes.splice(landCodes.indexOf(column['label']), 1)
            }
          }
          return landCodes.sort()
        }
  
        showNewFieldModal() {
          this.newField = []
          for(const column of this.tableColumns) {
            this.newField.push({label: column['label'], value: ''})
          }
          this.openNewFieldModal = true
        }
  
        async getTranslations() {
            const data = await db.collection(`/tenants/${store.state.tenantID}/settings/customTranslations/translations`).get()
            if(!data.size) { return }
            data.forEach((doc) => {
              this.translations[doc.id] = doc.data();
            });
  
            this.fields = Object.keys(this.translations[Object.keys(this.translations)[0]])
            for(const field of this.fields) {
              const map = {
                field: field,
              }
              for(const trans in this.translations) {
                const translation = this.translations[trans]
                map[trans] = translation[field]
              }
              this.tableData.push(map)
            }
            for (const language of Object.keys(this.translations)){
              const column = {
                name: language.toLowerCase(),
                label: language,
                field: language.toLowerCase(),
                align: 'left',
                sortable: true,
              }
              if(column.name !== 'name') {
                this.tableColumns.push(column)
              }
            }
        }
  
        addField() {
          const map = {
            field: this.newFieldName
          }
          for(const field of this.newField) {
            map[field.label] = field.value
          }
          this.tableData.push(map)
          this.newFieldName = ''
          this.newField = []
          this.openNewFieldModal = false
        }
  
        deleteField(field) {
          for(const data of this.tableData) {
            if(data.field === field) {
              this.tableData.splice(this.tableData.indexOf(data), 1)
            }
          }
        }
  
        deleteLanguage(column) {
          for(const data of this.tableData) {
            delete data[column.label]
          }
  
          for(const tableColumn of this.tableColumns) {
            if(tableColumn.label === column.label){
              this.tableColumns.splice(this.tableColumns.indexOf(column), 1)
            }
          }
          this.deletedLanguages.push(column.label)
          delete this.translations[column.label]
          this.landCodes = this.filterLandCodes()
        }
  
        async addLanguage() {
          this.showTable = false
          const data = this.tableData
          this.tableData = []
          
          const translation = {}
          for(const field of this.fields) {
            translation[field] = ''
          }
          this.translations[this.newLanguageName] = translation
          
          const column = {
            name: this.newLanguageName.toLowerCase(),
            label: this.newLanguageName,
            field: this.newLanguageName.toLowerCase(),
            align: 'left',
            sortable: true,
          }
          this.tableColumns.push(column)
          
          for(const field of data) {
            field[this.newLanguageName] = ''
            this.tableData.push(field)
          }
          this.newLanguageName = ''
          this.openNewLanguageModal = false
          this.showTable = true
          await this.saveData()
          this.tableData = []
          this.tableColumns = []
          await this.getTranslations()
          this.landCodes = this.filterLandCodes()
        }
  
        async saveData() {
          const promises = []
          for(const language of Object.keys(this.translations)) {
            const map = {}
            for(const data of this.tableData) {
              map[data.field] = data[language]
            }
            const promise = db.doc(`/tenants/${store.state.tenantID}/settings/customTranslations/translations/${language}`).set(map, {merge: true})
            promises.push(promise)
          }
          for(const language of this.deletedLanguages) {
            await db.doc(`/tenants/${store.state.tenantID}/settings/customTranslations/translations/${language}`).delete().then(() => {
                console.log("Document successfully deleted!");
            }).catch((error) => {
                console.error("Error removing document: ", error);
            });
          }
          await Promise.all(promises).then(() => {
            this.$q.notify({ message: `${this.$t('common.messages.recordChanged')}`, color: 'positive'})
          }).catch(() => {
            this.$q.notify({ message: `Fout tijdens het opslaan`, color: 'negative'})
          })
        }
    }
  