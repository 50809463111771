import { render, staticRenderFns } from "./CustomTranslations.vue?vue&type=template&id=6194d890&"
import script from "./CustomTranslations.vue?vue&type=script&lang=ts&"
export * from "./CustomTranslations.vue?vue&type=script&lang=ts&"
import style0 from "./CustomTranslations.vue?vue&type=style&index=0&id=6194d890&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QBtn,QIcon,QSeparator,QSpace,QTooltip,QTable,QTr,QTh,QInput,QDialog,QSelect,QToolbar,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtn,QIcon,QSeparator,QSpace,QTooltip,QTable,QTr,QTh,QInput,QDialog,QSelect,QToolbar})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
